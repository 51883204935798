<template>
  <DraftGuard :work-in-progress="workInProgress">
    <TwoSides>
      <!-- HEADER -->
      <template #header>
        <v-flex sm12 md6 px-3 mb-3 text-lg-right class="required-fields-label">
          {{ $t("global.fieldsRequired") }}
        </v-flex>
        <v-flex sm12 md6 mb-3></v-flex>
      </template>
      <!-- LEFT SIDE -->
      <template #left>
        <AddOrEditContactModal
          :isModalShow="isEditContactModalShow"
          :userId="complaint.caller ? complaint.caller.id : null"
          @onCloseModal="isEditContactModalShow = false"
          @customer-updated="setCaller"
        />
        <AddOrEditContactModal
          :isModalShow="isAddContactModalShow"
          @onCloseModal="isAddContactModalShow = false"
          @customer-created="setCaller"
        />
        <v-layout justify-space-around wrap>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 sm6 :class="{ 'pr-3': $vuetify.breakpoint.smAndUp }">
                <BaseSelect
                  :items="categories"
                  v-model="complaint.category"
                  :name="textsCpl.category"
                  required
                  solo
                  item-text="text"
                  item-value="id"
                  return-object
                  :error-messages="errors.category"
                  @input="$v.complaint.category.$touch()"
                  @blur="$v.complaint.category.$touch()"
                ></BaseSelect>
              </v-flex>
              <v-flex xs12 sm6 :class="{ 'pl-3': $vuetify.breakpoint.smAndUp }">
                <BaseSelect
                  :items="subcategories"
                  v-model="complaint.subCategory"
                  :name="textsCpl.subcategory"
                  required
                  solo
                  item-text="text"
                  item-value="id"
                  return-object
                  :error-messages="errors.subCategory"
                  @input="$v.complaint.subCategory.$touch()"
                  @blur="$v.complaint.subCategory.$touch()"
                ></BaseSelect>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <BaseInput
                  :name="textsCpl.title"
                  :value="complaint.title"
                  :hint="isShowTitleHintText ? $t('hints.maxTextLength') : null"
                  required
                  solo
                  :error-messages="errors.title"
                  @input="onTitleChange"
                  @blur="$v.complaint.title.$touch()"
                  @keyDown="onTitleKeyDown"
                ></BaseInput>
              </v-flex>
            </v-layout>

            <v-layout column>
              <v-flex xs12>
                <BaseTextarea
                  :name="textsCpl.description"
                  :value="complaint.description"
                  :hint="
                    isShowDescriptionHintText ? $t('hints.maxTextLength') : null
                  "
                  rows="3"
                  solo
                  required
                  :error-messages="errors.description"
                  @input="onDescriptionChange"
                  @keyDown="onDescriptionKeyDown"
                ></BaseTextarea>
              </v-flex>
            </v-layout>

            <v-layout
              id="complaint-caller-container"
              v-bind:class="isMobile && 'mobile'"
            >
              <v-flex xs6 pr-3>
                <CategoryField
                  ref="customerFieldRef"
                  autocompleteFieldAsync
                  return-object
                  required
                  solo
                  :options="searchCrmCustomer"
                  :disabled="!!setComplaintCaller"
                  :edit="true"
                  :label="textsCpl.caller"
                  :displayValueText="(item) => item.name || item.text"
                  :error-messages="errors.caller"
                  :hint="$t('common.autoCompleteMinimum3WordsHint')"
                  v-model="complaint.caller"
                  @input="$v.complaint.caller.$touch()"
                />
              </v-flex>
              <v-flex xs-3 id="complaint-caller-icons-container">
                <v-layout id="complaint-caller-icons-layout">
                  <v-btn
                    v-if="complaint.caller"
                    icon
                    text
                    @click="() => (isEditContactModalShow = true)"
                  >
                    <IconBox color="iconButton" :tooltip="$t('contacts.edit')">
                      stem-pen
                    </IconBox>
                  </v-btn>

                  <v-btn
                    icon
                    text
                    @click="() => (isAddContactModalShow = true)"
                  >
                    <IconBox color="iconButton" :tooltip="$t('newContact.add')">
                      stem-personPlus
                    </IconBox>
                  </v-btn>
                  <div v-if="selectedSubcategoryId">
                    <AttachmentModal
                      v-if="$can('CPL_READ')"
                      :attachmentsCount="complaint.attachments.length"
                      :subcategoryId="selectedSubcategoryId"
                      @onChange="onAttachmentsChange"
                    />
                  </div>
                  <div class="disabled-button" v-else>
                    <IconBox
                      color="disabled"
                      :tooltip="$t('complaints.selectFirstSubcategory')"
                    >
                      stem-attachment
                    </IconBox>
                  </div>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs6 pr-3 v-bind:class="isMobile && 'stretched-input'">
                <SelectWithAutocomplete
                  :readonly="!$can('CPL_WRITE', selectedCategoryId)"
                  :items="owners"
                  :name="textsCpl.owner"
                  :error-messages="errors.owner"
                  :clearable="false"
                  required
                  item-value="id"
                  :item-text="
                    (item) => `${item.surname} ${item.name} (${item.login})`
                  "
                  solo
                  v-model="complaint.owner"
                >
                  <OwnerListItem
                    slot="item"
                    slot-scope="{ item }"
                    :item="item"
                  />
                </SelectWithAutocomplete>
              </v-flex>
              <v-flex shrink>
                <v-btn
                  v-show="canLoggedUserBeOwner"
                  @click="assignToMe"
                  icon
                  text
                >
                  <IconBox
                    color="iconButton"
                    :tooltip="$t('common.assignToMe')"
                  >
                    stem-personChecked
                  </IconBox>
                </v-btn>
              </v-flex>
            </v-layout>

            <v-layout
              id="reported-time-container"
              v-bind:class="isMobile && 'mobile'"
            >
              <DateTimeField
                :dateLabel="textsCpl.insertDate"
                :timeLabel="textsCpl.insertTime"
                :isClearable="false"
                :minDateTime="currentDatetime"
                :value="complaint.insertDate"
                @input="onInserDateChange"
              />
            </v-layout>

            <!-- <v-layout
              id="expected-time-container"
              v-bind:class="isMobile && 'mobile'"
            >
              <DateTimeField
                :dateLabel="textsCpl.realisationDate"
                :timeLabel="textsCpl.realisationTime"
                :isClearable="false"
                disabled
                v-model="complaint.realisationDate"
              />
            </v-layout> -->

            <v-layout
              id="priority-source-container"
              v-bind:class="isMobile && 'mobile'"
            >
              <v-flex xs6 pr-3 v-bind:class="isMobile && 'stretched-input'">
                <BaseSelect
                  :items="priorityOptions"
                  v-model="complaint.priority"
                  :name="textsCpl.priority"
                  required
                  solo
                  item-text="text"
                  item-value="id"
                  :error-messages="errors.priority"
                  @input="$v.complaint.priority.$touch()"
                  @blur="$v.complaint.priority.$touch()"
                ></BaseSelect>
              </v-flex>
              <v-flex xs6 pl-3 v-bind:class="isMobile && 'stretched-input'">
                <BaseSelect
                  :items="sources"
                  v-model="complaint.source"
                  :name="textsCpl.source"
                  required
                  solo
                  item-text="text"
                  item-value="id"
                  :error-messages="errors.source"
                  @input="$v.complaint.source.$touch()"
                  @blur="$v.complaint.source.$touch()"
                ></BaseSelect>
              </v-flex>
            </v-layout>

            <SelectTagsField2
              v-model="complaint.tags"
              :allTags="allTags"
              :label="textsCpl.tags"
            />
          </v-flex>
        </v-layout>
      </template>
      <!-- RIGHT SIDE -->
      <template #right>
        <v-card v-if="complaint.subCategory.id" class="sub-category-card">
          <v-layout justify-space-around wrap>
            <v-flex xs11 mt-3>
              <v-flex justify-space-around justify-center wrap>
                <v-flex xs12>
                  <div class="text-h6 textColor--text">
                    {{ complaint.subCategory.text }}
                  </div>
                </v-flex>
                <v-flex xs12>
                  <ComplaintDynamic
                    class="my-3"
                    ref="details"
                    :subCategoryId="+complaint.subCategory.id"
                    :state="newComplaintStateId"
                    @loaded="getRefs"
                    :clone="isCloned"
                  ></ComplaintDynamic>
                </v-flex>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- BUTTONS -->
        <div class="buttons">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="cancel"
          >
            {{ textsCommon.cancel }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="saveData"
            :loading="loading && !exit"
          >
            {{ textsCommon.save }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            :loading="loading && exit"
            @click="
              exit = true;
              saveData();
            "
          >
            {{ textsCommon.saveAndExit }}
          </v-btn>
        </div>
        <ModalWindow ref="confirmModal" />
      </template>
    </TwoSides>
  </DraftGuard>
</template>

<script>
import { mapGetters } from "vuex";

import * as CplStates from "@/models/cplStates.js";
import { ComplaintsPropertyRestrictions } from "@/utils/ComplaintHelper";
import { EmitSuccess, EmitError } from "@/eventBus.js";
import { validateComplaintBeforeSave } from "@/mixins/validators";

import ComplaintNewService from "@/services/ComplaintNewService";
import ComplaintService from "@/services/ComplaintsService";
import DictionaryService from "@/services/DictionaryService";
import CrmCustomersService from "@/services/CrmCustomersService";

import ComplaintDynamic from "../components/Complaints/ComplaintDynamic";
import DateTimeField from "../components/Fields/DateTimeField";
import ComplaintValidators from "@/mixins/validators/complaint-validators";
import TwoSides from "../components/Shared/TwoSides";
import SelectTagsField2 from "../components/Fields/SelectTagsField2";
import AddOrEditContactModal from "@/components/Contacts/AddOrEditContactModal";
import AttachmentModal from "../components/Shared/Attachment/AttachmentModal";
import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";
import OwnerListItem from "@/components/ListItems/OwnerListItem";
import DraftGuard from "../components/DraftGuard";
import ModalWindow from "@/components/Shared/ModalWindow";
import CategoryField from "@/components/Fields/CategoryField";

export default {
  name: "NewComplaint",
  components: {
    DraftGuard,
    AttachmentModal,
    ComplaintDynamic,
    DateTimeField,
    SelectTagsField2,
    TwoSides,
    SelectWithAutocomplete,
    OwnerListItem,
    AddOrEditContactModal,
    ModalWindow,
    CategoryField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setPreviousDestinaion(from.name);
      if (to.params.selectedCustomer) {
        vm.setPreviousCustomer(to.params.selectedCustomer);
      }
    });
  },
  mixins: [ComplaintValidators],
  props: {
    complaintToClone: { type: Object },
    setComplaintCaller: { type: Object },
  },
  mounted() {
    this.getCategories();
    this.getPriorities();
    this.getSources();
    this.getTags();
    this.initializeYesNoSelect();
    if (this.complaintToClone) {
      this.setComplaintFromClone(this.complaintToClone);
    }

    this.complaint.owner = this.loggedUserId;
  },
  data() {
    return {
      exit: false,
      loading: false,
      newComplaintStateId: 1,
      complaint: {
        category: {},
        subCategory: {},
        title: "",
        description: "",
        caller: this.setComplaintCaller || null,
        owner: null,
        insertDate: this.$moment(),
        realisationDate: null,
        priority: null,
        source: null,
        tags: [], //tablica obiektów id/text
        attachments: [],
      },
      textsCpl: this.$t("complaints"),
      textsCommon: this.$t("common"),
      textGlobal: this.$t("global"),
      categories: [],
      subcategories: [],
      priorities: [],
      sources: [],
      owners: [],
      crmCustomers: [],
      customersToDisplay: [],
      trueFalse: [],
      allTags: [],
      searchedTag: "",
      validatedProperty: "complaint",
      detailsForClone: {},
      workInProgress: true,
      previousSelectedCustomer: null,
      previousDestination: null,
      currentDatetime: this.$moment(),
      isAddContactModalShow: false,
      isEditContactModalShow: false,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    selectedCategoryId() {
      return this.complaint.category && this.complaint.category.id
        ? this.complaint.category.id.toString()
        : "-1";
    },
    selectedSubcategoryId() {
      return this.complaint.subCategory.id || null;
    },
    canLoggedUserBeOwner() {
      return this.owners.some((o) => o.id === this.loggedUserId);
    },
    loggedUserId() {
      return this.$store.getters["oidcStore/oidcUser"] !== null
        ? this.$store.getters["oidcStore/oidcUser"].userId
        : null;
    },
    isCloned() {
      return (
        this.detailsForClone &&
        Object.entries(this.detailsForClone).length !== 0
      );
    },
    isShowTitleHintText() {
      return (
        this.complaint.title &&
        this.complaint.title.length >=
          ComplaintsPropertyRestrictions.maxTitleLength
      );
    },
    isShowDescriptionHintText() {
      return (
        this.complaint.description &&
        this.complaint.description.length >=
          ComplaintsPropertyRestrictions.maxDescriptionLength
      );
    },
    priorityOptions() {
      if (!this.complaint.category || this.priorities.length === 0) return [];

      const availableOptions = this.priorities.find(
        (item) => item.categoryId === this.complaint.category.id
      );

      return availableOptions ? availableOptions.priorities : [];
    },
  },
  watch: {
    "complaint.category"(newValue, oldValue) {
      if (this.attrsEmpty) this.complaint.subCategory = {};
      this.getSubcategories(newValue.id);
      this.setRealisationDateBySla(newValue.sla);
      this.updateOwners(newValue.id);

      if (!newValue || (oldValue || {}).id !== (newValue || {}).id) {
        this.complaint.priority = null;
      }
    },
    "complaint.insertDate": {
      immediate: true,
      handler() {
        if (this.complaint.subCategory.sla) {
          this.setRealisationDateBySla(this.complaint.subCategory.sla);
        } else {
          this.complaint.realisationDate = this.$moment(
            this.complaint.insertDate
          ).add(5, "d");
        }
      },
    },
    "complaint.subCategory"(subCategory) {
      if (!subCategory.id) {
        this.complaint.owner = null;
      } else {
        this.getDefaultTags(subCategory.id);
      }
      this.setRealisationDateBySla(subCategory.sla);

      if (subCategory && subCategory.id !== 1 && subCategory.id !== 2) {
        this.customersToDisplay = this.crmCustomers.filter(
          (customer) => customer.id !== "00000000-0000-0000-0000-000000000002"
        );

        if (
          this.complaint.caller &&
          this.complaint.caller.id === "00000000-0000-0000-0000-000000000002"
        ) {
          this.complaint.caller = null;
        }
      } else {
        this.customersToDisplay = this.crmCustomers;
      }
    },
  },
  methods: {
    onTitleChange(value) {
      this.complaint.title = value;
      this.$v.complaint.title.$touch();
    },
    onDescriptionChange(value) {
      this.complaint.description = value;
      this.$v.complaint.description.$touch();
    },
    onTitleKeyDown(event) {
      this.onKeyDown(
        event,
        event.target.value.length >=
          ComplaintsPropertyRestrictions.maxTitleLength
      );
    },
    onDescriptionKeyDown(event) {
      this.onKeyDown(
        event,
        event.target.value.length >=
          ComplaintsPropertyRestrictions.maxDescriptionLength
      );
    },
    onKeyDown(event, isMax) {
      //Backspace = 8, Delete = 46, KeyLeft = 37, KeyRight = 39, A = 65, C = 67, 86 = V, 88 = X
      if (
        event.keyCode === 8 ||
        event.keyCode === 46 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        (event.keyCode === 65 && event.ctrlKey) ||
        (event.keyCode === 67 && event.ctrlKey) ||
        (event.keyCode === 86 && event.ctrlKey) ||
        (event.keyCode === 88 && event.ctrlKey)
      ) {
        return;
      }

      if (isMax) {
        event.preventDefault();
      }
    },
    cancel() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            const destinationName =
              this.previousDestination === "customerComplaints"
                ? "customerComplaints"
                : "complaints";

            this.workInProgress = false;
            this.$nextTick(() =>
              this.$router.push({
                name: destinationName,
                params: {
                  initialSelectedCustomer: this.previousSelectedCustomer,
                },
              })
            );
          }
        });
    },
    assignToMe() {
      var loggedUser = this.owners.find((o) => o.id === this.loggedUserId);
      this.complaint.owner =
        loggedUser === this.complaint.owner ? null : loggedUser.id;
    },
    async saveData() {
      const modelValidation = await this.validateModelAsync();
      const detailsValidation = this.$refs["details"]
        ? this.$refs["details"].checkIsValid()
        : true;

      const isValidate = validateComplaintBeforeSave(this.complaint);

      if (!isValidate || !detailsValidation) {
        EmitError(this.$t("rules.validationErrorsBeforeSave"));
      } else if (modelValidation && detailsValidation) {
        this.loading = true;

        this.complaint.details = this.$refs["details"]
          .getModel()
          .filter((el) => {
            return !!el;
          });

        ComplaintNewService.Create(this.complaint)
          .then(async (response) => {
            EmitSuccess(
              this.$t("complaints.newCplCreated", {
                cpl: response.data.cplTopic,
              })
            );
            const route =
              this.exit || !response.data || !response.data.cplId
                ? { name: "complaints" }
                : {
                    name: "complaintDetails",
                    params: { id: response.data.cplId },
                  };

            this.workInProgress = false;
            await this.$nextTick();
            this.$router.push(route);
          })
          .finally(() => (this.loading = false));
      }
    },
    onAttachmentsChange(newAttachments) {
      this.complaint.attachments = newAttachments;
    },
    getCategories() {
      ComplaintNewService.GetCategories().then((response) => {
        this.categories = response;

        if (response.length === 1) {
          this.complaint.category = response[0];
        }
      });
    },
    getSubcategories(categoryId) {
      DictionaryService.GetSubcategories(categoryId).then((r) => {
        this.subcategories = r;

        if (r.length === 1) {
          this.complaint.subCategory = r[0];
        }
      });
    },
    getSubCategoryOwner(subCategoryId) {
      return ComplaintService.GetSubCategoryOwner(subCategoryId);
    },
    async updateOwners(categoryId) {
      this.owners = await ComplaintService.GetCategoryOwners(categoryId);
    },
    getPriorities() {
      ComplaintNewService.GetPrioritiesForCategory().then((r) => {
        this.priorities = r;
      });
    },
    getSources() {
      ComplaintNewService.GetSources().then((r) => {
        this.sources = r;
      });
    },
    getTags() {
      DictionaryService.GetAllTags().then((r) => {
        this.allTags = r;
      });
    },
    searchCrmCustomer(value) {
      if (value && value.length >= 3) {
        this.$refs.customerFieldRef.hideEmptyData = false;
        return CrmCustomersService.GetCrmCustomersBasicSearch(value);
      }

      this.$refs.customerFieldRef.hideEmptyData = true;
      return Promise.resolve();
    },
    initializeYesNoSelect() {
      this.trueFalse = [
        { id: 1, text: this.textsCommon.yes },
        { id: 2, text: this.textsCommon.no },
      ];
    },
    setRealisationDateBySla(slaValue) {
      if (slaValue >= 0) {
        this.complaint.realisationDate = this.$moment(
          this.complaint.insertDate
        ).add(slaValue, "h");
      }
    },
    setCaller(customer) {
      this.crmCustomers.push(customer);
      this.complaint.caller = customer;
    },
    setComplaintFromClone(complaint) {
      this.detailsForClone = complaint.details.find(
        (x) => x.stateId === CplStates.NEW
      );
      this.complaint.caller = complaint.caller;
      this.complaint.category = complaint.category;
      this.complaint.description = complaint.description;
      this.complaint.priority = complaint.priority.id;
      this.complaint.source = complaint.source.id;
      this.complaint.subCategory = complaint.subCategory;
      this.complaint.title = complaint.title;
      this.complaint.owner =
        !complaint.owner ||
        complaint.owner.id === "00000000-0000-0000-0000-000000000000"
          ? null
          : complaint.owner.id;
    },
    getRefs() {
      if (
        this.complaintToClone &&
        this.complaintToClone.subCategory.id ===
          this.complaint.subCategory.id &&
        this.detailsForClone
      ) {
        this.detailsForClone.params.forEach((el) => {
          this.$refs.details.model[el.paramId] = {
            dictParamId: el.dictParamId,
            dictParamValueId: el.dictParamValueId,
            paramId: el.paramId,
            paramName: el.paramName,
            paramTypeCode: el.paramTypeCode,
            paramValue: el.paramValue,
            required: this.$refs.details.model[el.paramId].required,
          };
        });
      }
    },
    onInserDateChange(value) {
      this.complaint.insertDate = value ? this.$moment(value) : null;
    },
    getDefaultTags(subcategoryId) {
      DictionaryService.GetSubcategoryTags(subcategoryId).then((response) => {
        this.complaint.tags = response;
      });
    },
    setPreviousDestinaion(previousDestination) {
      this.previousDestination = previousDestination;
    },
    setPreviousCustomer(customer) {
      this.previousSelectedCustomer = customer;
    },
  },
};
</script>

<style scoped lang="scss">
.required-fields-label {
  font-size: 12px;
}

::v-deep.date-time-field {
  width: 100%;
}

.sub-category-card {
  margin-bottom: 30px;
}

.buttons {
  float: right;
  margin-right: 30px;

  .v-btn {
    margin-left: 15px;
  }
}

.v-icon {
  cursor: pointer;
}

::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}

#complaint-caller-container {
  align-items: baseline;

  .disabled-button {
    margin-left: 5px;
  }
}

#complaint-caller-container.mobile {
  flex-direction: column;
}

#complaint-caller-container.mobile .flex.xs6.pr-3 {
  max-width: 100%;
  padding: 0px !important;
}

::v-deep#complaint-caller-container.mobile .v-input__slot {
  padding-bottom: 0;
  margin-bottom: 0;
}

#complaint-caller-container.mobile #complaint-caller-icons-layout {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

#complaint-caller-container.mobile #complaint-caller-icons-container {
  margin-bottom: 20px;
  margin-top: 10px;
}

#complaint-caller-container.mobile .input__control {
  min-height: auto;
}

::v-deep#complaint-caller-container.mobile .v-text-field__details {
  display: none;
}

::v-deep#priority-source-container.mobile {
  display: flex;
  flex-direction: column;
}

::v-deep.stretched-input {
  max-width: 100%;
  flex-grow: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

::v-deep#form-buttons-container.mobile,
::v-deep#reported-time-container.mobile,
::v-deep#expected-time-container.mobile {
  display: flex;
  flex-direction: column;
}

#complaint-caller-icons-layout {
  align-items: center;
}
</style>
