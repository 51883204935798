<template>
  <v-dialog
    content-class="contact-modal-dialog"
    persistent
    :value="showModal"
    :max-width="1200"
    @input="onCloseModal"
  >
    <div class="contact-modal-card">
      <v-tabs
        hide-slider
        background-color="transparent"
        :value="selectedViewTab"
        @change="onSelectViewTabChange"
      >
        <v-tab class="header-tab-item">
          <div :class="selectedViewTab === 0 ? 'header-tab-item-selected' : ''">
            {{ this.userId ? $t("contacts.edit") : $t("newContact.add") }}
          </div>
        </v-tab>
        <v-tab-item>
          <div class="contact-details-wrapper">
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="loaderColor"
            />
            <ContactEditor
              :contact="contact"
              :validationErrors="errorMessages"
              @onChange="onValueChange"
            />
          </div>
        </v-tab-item>
      </v-tabs>

      <div class="footer-section">
        <div class="buttons">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onSaveButtonClick"
          >
            {{ $t("global.save") }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onCloseModal"
          >
            {{ $t("common.cancel") }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";
import { mailChannel, smsChannel } from "@/models/channel.js";

import {
  validateContactBeforeSave,
  validateEmailOrPhoneCustomList,
} from "@/mixins/validators";
import { fillDefaultEmailAndPhoneIfEmpty } from "@/models/contactsHelper";

import CrmCustomersService from "@/services/CrmCustomersService";
import NewCrmCustomerService from "@/services/NewCrmCustomerService";

import ContactEditor from "@/components/Contacts/ContactEditor";

export default {
  name: "AddOrEditContactModal",
  components: {
    ContactEditor,
  },
  props: {
    isModalShow: { type: Boolean, default: false },
    userId: { type: String },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      selectedViewTab: 0,
      contact: this.initialContact(),
      errorMessages: {},
      emailsToValidateIfAlreadyUse: [],
      phonesToValidateIfAlreadyUse: [],
      validatedCorrectedEmails: [],
      validatedCorrectedPhones: [],
    };
  },
  watch: {
    isModalShow(newValue) {
      this.showModal = newValue;

      if (newValue) {
        this.restoreDefaultValues();

        if (this.userId) {
          this.fetchContact();
        } else {
          this.contact = this.initialContact();
          this.errorMessages = {};
        }
      }
    },
    "contact.firstname"(newValue) {
      this.errorMessages.firstname = !newValue
        ? this.$t("rules.required")
        : null;
    },
    "contact.surname"(newValue) {
      this.errorMessages.surname = !newValue ? this.$t("rules.required") : null;
    },
    "contact.type"(newValue) {
      this.errorMessages.type = !newValue ? this.$t("rules.required") : null;
    },
    "contact.emails"(newValues) {
      this.validateEmailsAfterChange(newValues);
    },
    "contact.phones"(newValues) {
      this.validatePhonesAfterChange(newValues);
    },
    emailsToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedEmails = [...this.validatedCorrectedEmails];
        const errors = this.errorMessages.emails;

        this.validateIfAlreadyUsed(newItems, EMAIL, errors, correctedEmails);
      }
    },
    phonesToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedPhones = [...this.validatedCorrectedPhones];
        const errors = this.errorMessages.phones;

        this.validateIfAlreadyUsed(
          newItems,
          MOBILEPHONE,
          errors,
          correctedPhones
        );
      }
    },
  },
  methods: {
    initialContact() {
      return {
        firstname: "",
        surname: "",
        description: "",
        type: null,
        insertDate: this.$moment(),
        emails: [mailChannel()],
        phones: [smsChannel()],
        owner: this.$store.getters["oidcStore/oidcUser"].userId,
        organization: null,
        department: null,
        groups: [],
        city: null,
        street: null,
        houseNo: null,
        postCode: null,
        relatedContacts: [],
      };
    },
    addContact() {
      this.isLoading = true;

      NewCrmCustomerService.CreateForComplaint(this.contact)
        .then((response) => {
          if (response) {
            this.$emit("customer-created", response);
            this.onCloseModal();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    editContact() {
      this.isLoading = true;
      const data = {
        ...this.contact,
        type: this.contact.type.id,
        owner: this.contact.owner ? this.contact.owner.id : null,
      };

      CrmCustomersService.UpdateCrmCustomerForComplaint(data)
        .then((response) => {
          if (response) {
            this.$emit("customer-updated", response);
            this.onCloseModal();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    fetchContact() {
      this.isLoading = true;

      CrmCustomersService.GetCrmCustomerDetails(this.userId)
        .then((response) => {
          this.validatedCorrectedEmails = response.emails.map(
            (item) => item.text
          );
          this.validatedCorrectedPhones = response.phones.map(
            (item) => item.text
          );

          this.contact = fillDefaultEmailAndPhoneIfEmpty(response);
        })
        .finally(() => (this.isLoading = false));
    },
    validateBeforeSave() {
      const { isValidationError, errors } = validateContactBeforeSave(
        this.contact,
        { ...this.errorMessages },
        this
      );

      this.errorMessages = errors;
      return isValidationError;
    },
    validateEmailsAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, EMAIL, this);

        this.errorMessages.emails = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.emailsToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedEmails.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.emails = [];
        this.emailsToValidateIfAlreadyUse = [];
      }
    },
    validatePhonesAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, MOBILEPHONE, this);

        this.errorMessages.phones = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.phonesToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedPhones.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.phones = [];
        this.phonesToValidateIfAlreadyUse = [];
      }
    },
    validateIfAlreadyUsed(itemsToValidate, type, errors, correctedItems) {
      const promises = [];

      itemsToValidate.forEach((item) => {
        promises.push(
          CrmCustomersService.CheckIsMediumTaken(
            item.value,
            type,
            this.contact.id
          )
        );
      });

      Promise.all(promises).then((responses) => {
        itemsToValidate.forEach((item, index) => {
          if (responses[index]) {
            errors[item.index] =
              type === EMAIL
                ? this.$t("rules.isUniqueFirstEmail")
                : this.$t("rules.isUniqueFirstPhone");
          } else {
            correctedItems.push(item.value);
          }
        });

        if (type === EMAIL) {
          this.errorMessages = {
            ...this.errorMessages,
            emails: errors,
          };
          this.validatedCorrectedEmails = correctedItems;
        } else if (type === MOBILEPHONE) {
          this.errorMessages = {
            ...this.errorMessages,
            phones: errors,
          };
          this.validatedCorrectedPhones = correctedItems;
        }
      });
    },
    onSelectViewTabChange(index) {
      this.selectedViewTab = index;
    },
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onSaveButtonClick() {
      const isValidationError = this.validateBeforeSave();

      if (!isValidationError) {
        if (this.userId) {
          this.editContact();
        } else {
          this.addContact();
        }
      }
    },
    onValueChange(newValue) {
      this.contact = { ...this.contact, ...newValue };
    },
    restoreDefaultValues() {
      this.isLoading = false;
      this.errorMessages = {};
    },
  },
};
</script>

<style lang="scss">
.v-dialog.contact-modal-dialog {
  box-shadow: none !important;
}

.contact-modal-card {
  .v-slide-group__content {
    padding-top: 2px; // to display box shadow on top

    .header-tab-item {
      min-width: 150px;
      border-radius: 0 20px 0 0;
      background-color: $tab-item-background;
      font-weight: bold;
      margin-bottom: -2px; // to hide box shadow on tab's bottom
      box-shadow: 1px -1px 5px -3px rgba(66, 68, 90, 1);

      &.v-tab--active {
        background-color: white;
      }

      .icon-wrapper {
        margin-left: 5px;
      }

      .go-back-arrow {
        margin-left: -20px;
        margin-right: 10px;
      }

      .header-tab-item-selected {
        color: $text-title;
      }
    }

    .header-tab-item::before {
      border-radius: 0 20px 0 0;
    }
  }

  .contact-details-wrapper {
    padding-bottom: 5px;
  }

  .footer-section {
    display: flex;
    padding: 20px;
    background-color: white;

    .buttons {
      margin-left: auto;

      > button {
        margin-left: 10px;
      }
    }
  }
}
</style>
